import React, { useState, useEffect, useRef, useCallback } from "react"
import BaseContext from "../context/baseContext"
// import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa"
import styled from "@emotion/styled"
import Transition from "./Transition"
import { Link } from "gatsby"
import GImage from "./GImage"

const InsetBoxShadow = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-image: url("/images/shadow-sm.png");
  @media (min-width: 600px) {
    background-image: url("/images/shadow.png");
  }
`

// box-shadow: 0px 20px 100px 50px rgba(0, 0, 0, 0.6) inset;
//   -webkit-box-shadow: 0px 20px 100px 50px rgba(0, 0, 0, 0.6) inset;
//   -moz-box-shadow: 0px 20px 100px 50px rgba(0, 0, 0, 0.6) inset;
//   @media (min-width: 600px) {
//     box-shadow: 0px -70px 250px 50px rgba(0, 0, 0, 0.8) inset;
//     -webkit-box-shadow: 0px -70px 250px 50px rgba(0, 0, 0, 0.8) inset;
//     -moz-box-shadow: 0px -70px 250px 50px rgba(0, 0, 0, 0.8) inset;
//   }

const H2WithShadow = styled.h2`
  text-shadow: 0px 2px 8px rgb(0, 0, 0, 0.8);
`
const H1WithShadow = styled.h1`
  text-shadow: 0px 2px 8px rgb(0, 0, 0, 0.8);
`

let interval = null
const ImageSlider = ({ slides, text1, text2, autoPlay }) => {
  const autoPlayRef = useRef(null)
  const [current, setCurrent] = useState(0)
  const length = slides ? slides.length : 0

  const headerRef = React.useContext(BaseContext)

  const play = () => {
    autoPlayRef.current()
  }

  const nextSlide = useCallback(() => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }, [length, current])

  // const prevSlide = () => {
  //   setCurrent(current === 0 ? length - 1 : current - 1)
  // }

  useEffect(() => {
    autoPlayRef.current = nextSlide
    if (autoPlay) {
      interval = setInterval(play, autoPlay * 1000)
    }
    return () => {
      if (autoPlay) {
        clearInterval(interval)
      }
    }
  }, [autoPlay, nextSlide])
  // interval,

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null
  }

  return (
    <div className="h-80vh lg:h-screen select-none">
      <section
        ref={headerRef}
        className="slider bg-gray-900 relative w-full overflow-hidden shadow-sm h-full"
      >
        {/* <FaArrowAltCircleLeft
        className="left-arrow absolute top-1/2 left-8 text-xl font-gray-900 z-10 cursor-pointer select-none"
        onClick={prevSlide}
      />
      <FaArrowAltCircleRight
        className="right-arrow absolute top-1/2 right-8 text-xl font-gray-900 z-10 cursor-pointer select-none"
        onClick={nextSlide}
      /> */}
        <InsetBoxShadow className="h-full absolute inset-0 z-10">
          <div className="flex justify-center align-center items-center h-full">
            <Transition
              show={true}
              appear={true}
              className=""
              enter="transition ease-in-out duration-3000 transform order-first"
              enterStart="opacity-0 -translate-y-8"
              enterEnd="opacity-100 translate-y-0"
            >
              <div className="text-center">
                {text1 && (
                  <H2WithShadow className="text-stone-100 font-bold mb-2 sm:mb-5 text-4xl sm:text-5xl text-shadow">
                    {text1}
                  </H2WithShadow>
                )}
                {text2 && (
                  <H1WithShadow className="text-stone-100 font-bold text-2xl sm:text-3xl">
                    <Link
                      title="Découvrez l'histoire du Domaine"
                      to="/histoire"
                    >
                      {text2}
                    </Link>
                  </H1WithShadow>
                )}
              </div>
            </Transition>
          </div>
        </InsetBoxShadow>
        {slides.map((slide, index) => {
          return (
            <div
              key={slide.id}
              className={
                index === current
                  ? "w-full slide active absolute inset-0 opacity-100 transition duration-2000 ease-in-out"
                  : "w-full slide absolute inset-0 opacity-0 transition duration-3000 ease-in-out"
              }
            >
              <GImage
                objectPosition="50% top"
                objectFit="cover"
                className="h-80vh lg:h-screen"
                imgClassName="h-80vh lg:h-screen"
                src={slide.directus_files_id.imageFile}
                alt={`Photo n°${index + 1} du vignoble de Jape-loup`}
              />
            </div>
          )
        })}
      </section>
    </div>
  )
}

export default ImageSlider
