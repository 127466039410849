import React, { useRef } from "react"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import Informations from "../components/informationComponents/Informations"
import ContactForm from "../components/formComponents/ContactForm"
import StyledTitle from "../components/StyledTitle"
import GImage from "../components/GImage"
import ImageSlider from "../components/ImageSlider"
import Footer from "../components/Footer"

const Home = ({ data: gqlData }) => {
  const { directus, grappe, cave } = gqlData

  const componentRef = useRef(null)

  const params = {
    contactForm: {
      defaultSubject: "visiter",
      title: {
        text: "Contactez-nous",
        variant: "h3",
        component: "h3",
      },
    },
    mentionsLegales: {
      ariaLabel: "mentions-legales",
      title: "Informations légales",
      content: "",
    },
  }

  return (
    <>
      <div ref={componentRef}>
        <Seo
          title="Vignoble de Jape-loup à Saint-Alexandre - Visite et dégustation de vin"
          description="Vigneron récoltant, vente directe au caveau. Le vignoble de Jape-Loup est niché dans un écrin de verdure au sud de la vallée du Rhône, à Saint-Alexandre."
        />
        <>
          <ImageSlider
            autoPlay={8}
            text1={directus.homepage.text_slider1}
            text2={directus.homepage.text_slider2}
            slides={directus.homepage.slides}
          />
          <div className="pb-14">
            {/* 1) PRESENTATION - 2) BOUTEILLES 
            3) GRAPPE       - 4) CONTACT
            5) COORDONNEES  - 6) CAVE
            */}
            <div className="relative mt-8 pb-7 mb-4 pt-14 px-8 bg-stone-50 border-2 border-stone-100 flex flex-wrap justify-center">
              {directus && directus.homepage && (
                <div className="w-full flex justify-center">
                  <StyledTitle
                    text={directus.homepage.title}
                    component="h2"
                    className="h1-variant font-bold"
                    darkTheme={false}
                  />
                </div>
              )}
              <div
                className="w-full flex justify-center"
                dangerouslySetInnerHTML={{
                  __html: `${directus.homepage.description}`,
                }}
              />
              <div className="absolute bottom-[-23px]">
                <Link
                  className="font-bold border-[1px] shadow-sm border-stone-100 bg-white px-5 py-2 flex items-center justify-center"
                  title="Découvrez les vins du vignoble"
                  to="/les-vins"
                >
                  Liste des vins
                </Link>
              </div>
            </div>
            {directus.homepage.images && (
              <div className="relative mx-auto mb-8 mt-8 py-4 px-6 w-full flex justify-start md:justify-center gap-8 overflow-x-auto scroll-px-6 snap-x snap-proximity md:scroll-px-0 md:max-w-screen-2xl md:snap-none md:overflow-x-visible">
                {directus.homepage.images.map((image) => {
                  return (
                    <div key={image.id} className={`snap-center w-full`}>
                      <GImage
                        src={image.directus_files_id.imageFile}
                        style={{ display: "block" }}
                        className="w-80 aspect-[1/1] md:w-full h-auto max-w-104"
                      />
                    </div>
                  )
                })}
              </div>
            )}
            <div className="pb-12 lg:py-4 px-6 grid grid-cols-1 lg:grid-cols-5 lg:grid-flow-row lg:auto-rows-min gap-8 max-w-screen-2xl mx-auto">
              <div className="px-8 sm:px-20 pt-20 pb-6 bg-stone-50 border-2 border-stone-100 relative overflow-hidden lg:col-span-3">
                <StyledTitle
                  text={params.contactForm.title.text}
                  component="h3"
                  className="h1-variant font-bold"
                  darkTheme={false}
                />
                <ContactForm
                  defaultSubject={params.contactForm.defaultSubject}
                />
              </div>

              <div className="lg:col-span-2">
                <GImage
                  src={grappe}
                  style={{ display: "block" }}
                  className="w-full h-full mx-auto lg:mx-0"
                />
              </div>
            </div>
            <div className="lg:py-4 px-6 grid grid-cols-1 lg:grid-cols-5 grid-flow-row auto-rows-min gap-8 max-w-screen-2xl mx-auto">
              <div className="lg:col-span-3 order-last lg:order-first">
                <GImage
                  src={cave}
                  style={{ display: "block" }}
                  className="w-full h-full ml-auto mr-0"
                />
              </div>

              <div className="relative overflow-hidden lg:col-span-2 mx-auto lg:mx-0">
                <div className="pl-8 pr-10 pt-20 pb-6 flex justify-center w-full bg-stone-50 border-2 border-stone-100 mx-auto h-full">
                  <div className="">
                    <Informations darkTheme={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      {directus.pages_settings.mentions && (
        <Footer mentions={directus.pages_settings.mentions} />
      )}
    </>
  )
}

export default Home

export const pageQuery = graphql`
  query {
    # locales: allLocale(filter: { language: { eq: $language } }) {
    #   edges {
    #     node {
    #       ns
    #       data
    #       language
    #     }
    #   }
    # }
    # diaporama: allFile(filter: { sourceInstanceName: { eq: "diaporama" } }) {
    #   nodes {
    #     relativePath
    #     childImageSharp {
    #       gatsbyImageData(layout: FULL_WIDTH)
    #     }
    #   }
    # }
    directus: allDirectus {
      homepage {
        title
        description
        text_slider1
        text_slider2
        slides(sort: ["sort"]) {
          id
          directus_files_id {
            id
            filename_disk
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 80
                  placeholder: TRACED_SVG
                  tracedSVGOptions: { color: "#f3f3ed" }
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        images(sort: ["sort"]) {
          id
          directus_files_id {
            id
            filename_disk
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 80
                  width: 600
                  placeholder: TRACED_SVG
                  tracedSVGOptions: { color: "#f3f3ed" }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
      pages_settings {
        mentions
      }
    }
    # millesime: file(relativePath: { eq: "millesime_2019.jpg" }) {
    #   childImageSharp {
    #     gatsbyImageData(
    #       layout: CONSTRAINED
    #       quality: 80
    #       placeholder: TRACED_SVG
    #       tracedSVGOptions: { color: "#f3f3ed" }
    #     )
    #   }
    # }
    grappe: file(relativePath: { eq: "les-vins/grappe.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 80
          width: 600
          placeholder: TRACED_SVG
          tracedSVGOptions: { color: "#f3f3ed" }
        )
      }
    }
    cave: file(relativePath: { eq: "les-vins/cave.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 80
          placeholder: TRACED_SVG
          tracedSVGOptions: { color: "#f3f3ed" }
        )
      }
    }
  }
`
